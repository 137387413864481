export const environment = {
    production: true,
    elemental: false,
    deploymentName: 'Legend',
    companyName: 'Legend Smelting and Recycling',
    website: 'legenddatabase.com',
    converterImageLogo: '/assets/images/LSRRoundLogo.png',
    converterImageLogoSmall: '/assets/images/LSRRoundLogo_small.png',
    converterWatermarkText: 'LEGEND SMELTING|AND RECYCLING',
    dashboardLogoLight: '/assets/images/logo_dark.png',
    dashboardLogoDark: '/assets/images/logo_light.png',
    dashboardLogoAspectRatio: 5,
    welcomeLogo: '/assets/images/AppLogo.png',
    welcomePageUrl: 'https://www.legendsmeltingrecycling.com/',
    hasWelcomePageLinks: true,
    defaultApiEndpoint: 1,
    logLevel: 2,
    versionCheckUrl: 'https://test.legenddatabase.com/version.json',
    azureMapsKey:
        '1ylXy1Xw4ENtG2DBwAaH1xZiSfmyWF0NwX1yy529xeSTPQVbwJ9yJQQJ99AKACYeBjF03xPwAAAgAZMP2wJ3',
};
